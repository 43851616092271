//Guest
export const apiGetAllTopicGuest = "Guest/GetAllTopic"
export const apiGetByRegionId = "Guest/GetByRegionId"
export const apiGetAllCourse = "Guest/GetAllCourse"
export const apiGetAllCourseHome = "Guest/GetAllCourseHome"
export const apiGetAllTopicByCourse = "Guest/GetAllTopicByCourse"
export const apiGetDetailCourse = "Guest/GetDetailCourse"
export const apiGetListBannerFooterEvent = "Guest/GetListBannerFooterEvent"
export const apiGetListEvent = "Guest/GetListEventGuest"
export const apiGetTeacherHome = "Guest/GetTearcher"
export const apiGetPostHome = "Guest/GetPostHome"
