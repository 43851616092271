import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Space, Table, DatePicker, Row, Col, Form, Select } from 'antd';
import { Tabs } from "antd"
import BiddingPakage from "src/services/BiddingPakage"
import { DEFAULT_PAGE_SIZE } from "src/constants/pageSizeOptions"
import ListTable from './ListTable';
import moment from "moment"
import FlInput from "src/components/FloatingLabel/Input"
import { StyledFileUser } from '../styled';
const JobApplication = () => {
    const [current, setCurrent] = useState("1")
    const { AccountId } = useParams()
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const [total, setTotal] = useState()
    const [textSearch, setTextSearch] = useState()
    const [data, setData] = useState()
    const [number, setNumber] = useState(0)
    const [paginations, setPagination] = useState({
        CurrentPage: 1,
        PageSize: DEFAULT_PAGE_SIZE,
        BidID: location.state.BidID,
        AccountID: location.state.AccountOldID,
        TextSearch: "",
        Status: 0,
        CreateDate: "",
    })


    const onClick = e => {
        setCurrent(e.key)
    }

    const getListCandidateIn = async () => {
        try {
            setLoading(true)
            const res = await BiddingPakage.GetAllCandidateIn({
                ...paginations,
            })
            if (res?.isError) return
            setData(res?.Object?.Data)
            setTotal(res?.Object?.Total)

        } finally {
            setLoading(false)
        }
    }
    // const handledChangeText = (event) => {
    //     setTextSearch(event.target.value)
    // }
    useEffect(() => {
        getListCandidateIn()
    }, [paginations, AccountId, number])
    const columns = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            width: 60,
            render: (val, record, idx) => (
                <div className="text-center">
                    {/* {idx + 1 + pagination.PageSize * (pagination.CurrentPage - 1)} */}
                </div>
            ),
            align: "center",
        },
        {
            title: 'Tên',
            dataIndex: '',
            render: (_, record) => (
                <Space size="middle">
                    <div className='d-flex'>
                        {record?.FullName || "VnCert"}
                    </div>

                </Space>
            ),
        },
        {
            title: 'Kỹ Năng',
            dataIndex: '',
            render: (record) => (
                <Space size="middle">
                    <StyledFileUser>
                        {record?.ltSkill.map(skill => {
                            return (
                                <span className="job-overview_title_skill">
                                    {skill?.Name}
                                </span>
                            )
                        })}
                    </StyledFileUser>
                </Space>
            ),
        },
        {
            title: 'Đơn giá ',
            dataIndex: '',

            render: (record) => (
                <Space size="middle">
                    {record?.DesiredUnitPrice}
                </Space>
            ),

        },
        {
            title: 'Ngày ứng tuyển ',
            dataIndex: '',
            render: (record) => (
                <Space size="middle">
                    {moment(record?.CreateDate).format(
                        "DD/MM/YYYY")}
                </Space>
            ),

        },

        {
            title: 'Trạng thái',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.Status === 1 ? 'Đang Tuyển' : record.Status === 2 ? 'Ngừng Tuyển' : ''}
                </Space>
            ),
        },
    ];
    const handleChangeCompanyUser = () => {
        console.log('ok')

    }
    const items = [
        {
            label: " Tất cả",
            key: "0",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
            // <div>{location.state.BidID}</div>
        },
        {
            label: "Chờ xác nhận",
            key: "1",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />
        },
        {
            label: "Hủy ứng tuyển",
            key: "2",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },
        {
            label: "Từ chối CV",
            key: "3",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },
        {
            label: "Đặt lịch",
            key: "4",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },
        {
            label: "Xác nhận lịch",
            key: "5",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },
        {
            label: "PV Thất bại",
            key: "6",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },
        {
            label: "Thành công",
            key: "7",
            icon: "",
            children: <ListTable
                handleChangeCompanyUser={handleChangeCompanyUser}
                pagination={{

                    hideOnSinglePage: total <= 10,
                    current: paginations.CurrentPage,
                    pageSize: paginations.PageSize,
                    responsive: true,
                    total,
                    locale: { items_per_page: "" },
                    showSizeChanger: total > 10,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...paginations,
                            CurrentPage: page,
                            PageSize: pageSize,
                        })
                    },
                }}

                columns={columns} setPagination={setPagination} data={data} paginations={paginations} />,
        },

    ]
    const onChange = (key) => {
        setNumber(key)
        setPagination({
            ...paginations,
            Status: key
        })
    };

    return (
        <Row>
            <Col span={24}>

                <Tabs
                    onChange={onChange}
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                />
            </Col>
        </Row>
    );
}

export default JobApplication;
