import { Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import STORAGE, { getStorage } from "src/lib/storage"
import CommonService from "src/services/CommonService"
import "./App.css"
import ModalLoading from "./components/Modal/Loading"
import { getListSystemKey, setListTabs, setUserInfo } from "./redux/appGlobal"
import AppRouter from "./router/AppRouter"
import RoleService from "./services/RoleService"
import GuestServices from "./services/GuestService"
import { setBanner, setEvent, setFooter } from "./redux/banner"
import { ACCOUNT_TYPE_ID } from "./constants/constants"

function App() {
  const isLogin = getStorage(STORAGE.TOKEN)
  const dispatch = useDispatch()
  const { modalLoading } = useSelector(state => state.common)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // getBaner()
  }, [])
  const getBaner = async () => {
    const res = await GuestServices.getListBanner()
    if (res?.isError) return
    dispatch(setBanner(res?.Object?.BannerFooter?.ListBanner))
    dispatch(setBanner(res?.Object?.ListBanner))
    dispatch(
      setFooter({
        Footer: res?.Object?.Footer,
        Map: res?.Object?.Map,
      }),
    )
    const resEvent = await GuestServices.getListEvent()
    dispatch(setEvent(resEvent?.Object))
  }
  useEffect(() => {
    if (!!isLogin) getData()
  }, [isLogin])

  const getSystemKey = async () => {
    const res = await CommonService.getSystemKey("All")
    if (res.IsError) return
    dispatch(getListSystemKey(res.Object))
  }
  const getData = async () => {
    try {
      setLoading(true)
      dispatch(setUserInfo(getStorage(STORAGE.USER_INFO)))
      if (
        getStorage(STORAGE.USER_INFO)?.AccountType !== ACCOUNT_TYPE_ID.HocVien
      ) {
        const resp = await RoleService.getListTab()
        if (resp.isOk) {
          dispatch(setListTabs(resp.Object))
        }
      }
      getSystemKey()
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="layout-center">
      <div className="layout-max-width">
        {loading ? (
          <div className="loading-center" style={{ height: "100vh" }}>
            <Spin />
          </div>
        ) : (
          <AppRouter />
        )}
      </div>
      {!!modalLoading && <ModalLoading />}
    </div>
  )
}

export default App
