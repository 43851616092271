import {
  apiGetAll,
  apiGetAllSkill,
  apiInsertBiddingPackage,
  apiUpdateBiddingPackage,
  apiDeleteBiddingPackage,
  apiSaveBiddingPackage,
  apiUnSaveBiddingPackage,
  apiGetSaveBiddingPackageByAccount,
  apiGetAllCompanyIn,
  apiGetAllByAccount,
  apiGetAllBiddingPackageIn,
  apiGetListOfCandidatesParticipating,
  apiGetAllCandidateIn,
  apiJoinBiddingPackage,
  apiDetailCalendar,
  apiGetBookedCandidate,
} from "./urls"
import http from "../index"
import QueryString from "qs"
const getListAll = body => http.post(apiGetAll, body)
const GetAllSkill = () => http.get(apiGetAllSkill)
const InsertBiddingPackage = body => http.post(apiInsertBiddingPackage, body)
const UpdateBiddingPackage = body => http.put(apiUpdateBiddingPackage, body)
const DeleteBiddingPackage = params =>
  http.patch(`${apiDeleteBiddingPackage}?${QueryString.stringify(params)}`)
const SaveBiddingPackage = body => http.post(apiSaveBiddingPackage, body)

const UnSaveBiddingPackage = params =>
  http.patch(`${apiUnSaveBiddingPackage}?${QueryString.stringify(params)}`)
const GetSaveBiddingPackageByAccount = data =>
  http.post(apiGetSaveBiddingPackageByAccount, data)
const GetAllCompanyIn = data => http.post(apiGetAllCompanyIn, data)
const GetAllBiddingPackageIn = data =>
  http.post(apiGetAllBiddingPackageIn, data)
const GetAllByAccount = data => http.post(apiGetAllByAccount, data)
const getParticipating = () => http.get(apiGetListOfCandidatesParticipating)
const GetAllCandidateIn = data => http.post(apiGetAllCandidateIn, data)
const GetJoinBiddingPackage = data => http.post(apiJoinBiddingPackage, data)
const GetDetailCalendar = params => http.get(`${apiDetailCalendar}${params}`)
const GetBookedCandidate = body => http.post(apiGetBookedCandidate, body)
const BiddingPakage = {
  GetJoinBiddingPackage,
  GetAllCandidateIn,
  GetAllByAccount,
  GetAllBiddingPackageIn,
  GetAllCompanyIn,
  getListAll,
  GetAllSkill,
  InsertBiddingPackage,
  UpdateBiddingPackage,
  DeleteBiddingPackage,
  SaveBiddingPackage,
  UnSaveBiddingPackage,
  GetSaveBiddingPackageByAccount,
  getParticipating,
  GetDetailCalendar,
  GetBookedCandidate,
}
export default BiddingPakage
