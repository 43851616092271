import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import JobApplication from "src/pages/USER/FileUser/Components/JobApplication"
// ANONYMOUS
const LoginGoogle = React.lazy(() => import("src/pages/LoginGoogle"))
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const Contact = React.lazy(() => import("src/pages/ANONYMOUS/Contact"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ProfileManager = React.lazy(() => import("src/pages/USER/ProfileManager"))
const SearchJob = React.lazy(() => import("src/pages/USER/SearchJob"))
const Personnel = React.lazy(() => import("src/pages/USER/Personnel"))
const CompanyInfo = React.lazy(() => import("src/pages/USER/CompanyInfo/index"))
const JobSave = React.lazy(() => import("src/pages/USER/JobSave/index"))
const FileUser = React.lazy(() => import("src/pages/USER/FileUser/index"))
const DetailCard = React.lazy(() =>
  import("src/pages/USER/SearchJob/Components/DetailCard"),
)
const FileCompany = React.lazy(() =>
  import("src/pages/USER/FileUser/Components/FileCompany"),
)

const DashboardUse = React.lazy(() =>
  import("src/pages/USER/DashboardUse/DashboardUse"),
)
const Jobpostings = React.lazy(() =>
  import("src/pages/USER/JobPostings/Jobpostings"),
)
const Dashboard = React.lazy(() => import("src/pages/USER/Dashboard"))
const InterviewSchedule = React.lazy(() =>
  import("src/pages/USER/InterviewSchedule/InterviewSchedule"),
)
const ListCandidates = React.lazy(() => import("src/pages/USER/ListCandidates"))
const CandidateRecruitment = React.lazy(() =>
  import("src/pages/ADMIN/CandidateRecruitment"),
)
const ServicePack = React.lazy(() =>
  import("src/pages/USER/ServicePack/ServicePack"),
)

// ADMIN
const RequestSupport = React.lazy(() =>
  import("src/pages/ADMIN/RequestSupport"),
)
const BannerFooter = React.lazy(() => import("src/pages/ADMIN/BannerFooter"))
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const PostManager = React.lazy(() => import("src/pages/ADMIN/PostManager"))
const Tags = React.lazy(() => import("src/pages/ADMIN/Tags"))
const Position = React.lazy(() => import("src/pages/ADMIN/Position"))
const UserContact = React.lazy(() => import("src/pages/ADMIN/UserContact"))
const BusinessContact = React.lazy(() =>
  import("src/pages/ADMIN/BusinessContact"),
)
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
const PackageService = React.lazy(() =>
  import("src/pages/ADMIN/PackageService"),
)

const PersonneBook = React.lazy(() => import("src/pages/USER/PersonnelBook"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.LIEN_HE,
    element: (
      <LazyLoadingComponent>
        <Contact />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.LOGIN_GOOGLE,
    element: (
      <LazyLoadingComponent>
        <LoginGoogle />
      </LazyLoadingComponent>
    ),
  },

  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.BANNER_FOOTER,
        element: (
          <LazyLoadingComponent>
            <BannerFooter />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.YEU_CAU_HO_TRO,
        element: (
          <LazyLoadingComponent>
            <RequestSupport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.GOI_DICH_VU,
        element: (
          <LazyLoadingComponent>
            <PackageService />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_BAI_VIET,
        element: (
          <LazyLoadingComponent>
            <PostManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_MUC_THE,
        element: (
          <LazyLoadingComponent>
            <Tags />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_NHAN_VIEN,
        element: (
          <LazyLoadingComponent>
            <UserContact />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_DOANH_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <BusinessContact />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHUC_VU,
        element: (
          <LazyLoadingComponent>
            <Position />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.QUAN_LY_TONG_QUAN,
        element: (
          <LazyLoadingComponent>
            <DashboardUse />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.TONG_QUAN}/:id`,
        element: (
          <LazyLoadingComponent>
            <Dashboard />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_HO_SO,
        element: (
          <LazyLoadingComponent>
            <ProfileManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_TIN_DANG_TUYEN,
        element: (
          <LazyLoadingComponent>
            <Jobpostings />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_SU_TIEM_NANG,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <Personnel />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TIM_VIEC_COMPANY,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <SearchJob />
            </div>
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.THONG_TIN_CONG_TY,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <CompanyInfo />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.VIEC_LAM_DA_LUU,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <JobSave />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHI_TIET_VIEC_LAM,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <DetailCard />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_UNG_TUYEN,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <FileUser />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CONG_TY_UNG_TUYEN,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <FileCompany />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LICH_PHONG_VAN,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <InterviewSchedule />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_VIEN_UNG_TUYEN,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <JobApplication />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_UNG_TUYEN,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <ListCandidates />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.GOI_DICH_VU_USER,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <ServicePack />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.UNG_TUYEN_DI,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <CandidateRecruitment />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_SU_DUOC_BOOK,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <CandidateRecruitment />
            </div>
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_SU_BOOK,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <PersonneBook />
            </div>
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TIM_VIEC,
        element: (
          <LazyLoadingComponent>
            <div style={{ background: "white" }}>
              <SearchJob />
            </div>
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
