const QUAN_LY = "/quan-ly/"
const ROUTER = {
  LOGIN_GOOGLE: "/dang-nhap-tu-mang-xa-hoi",
  HOME: "/",
  SVG_VIEWER: "/svg-viewer",
  TIN_TUC: "/tin-tuc",
  LIEN_HE: "/lien-he",
  TIM_KIEM: "/tim-kiem",

  // ADMIN
  //Dịch vụ
  YEU_CAU_HO_TRO: "/yeu-cau-ho-tro",
  TAO_TIN_MOI: "/tao-tin-moi",
  GOI_DICH_VU: "/goi-dich-vu",
  //Bài viết
  DANH_SACH_BAI_VIET: "/danh-sach-bai-viet",
  DANH_MUC_THE: "/danh-muc-the",
  DANG_BAI: "/dang-bai",

  //Banner
  BANNER_FOOTER: "/banner-footer",
  //quản trị
  PHAN_QUYEN: "/phan-quyen",
  CHUC_VU: "/chuc-vu",
  DANH_BA_DOANH_NGHIEP: "/danh-ba-doanh-nghiep",
  DANH_BA_NHAN_VIEN: "/danh-ba-nhan-vien",
  //Khóa học
  DANH_BA: "/danh-ba",

  //User
  // TONG_QUAN: "/tong-quan",

  TIM_VIEC: "/tim-viec",
  CHI_TIET_VIEC_LAM: "chi-tiet-viec-lam/:id",

  //Trang quản lý
  TRANG_QUAN_LY: QUAN_LY,
  QUAN_LY_TONG_QUAN: `${QUAN_LY}tong-quan`,
  QUAN_LY_TIN_DANG_TUYEN: `${QUAN_LY}tin-dang-tuyen`,
  NHAN_SU_TIEM_NANG: `${QUAN_LY}nhan-su-tiem-nang`,
  QUAN_LY_HO_SO: `${QUAN_LY}ho-so`,
  THONG_TIN_CONG_TY: `${QUAN_LY}thong-tin-cong-ty`,
  VIEC_LAM_DA_LUU: `${QUAN_LY}da-luu`,
  HO_SO_UNG_TUYEN: `${QUAN_LY}ho-so-ung-tuyen`,
  CONG_TY_UNG_TUYEN: `${QUAN_LY}ho-so-ung-tuyen/cong-ty-ung-tuyen/:id`,
  NHAN_VIEN_UNG_TUYEN: `${QUAN_LY}ho-so-ung-tuyen/cong-ty-ung-tuyen/:id/:id`,
  LICH_PHONG_VAN: `${QUAN_LY}lich-phong-van`,
  TIM_VIEC_COMPANY: `${QUAN_LY}tim-viec-company`,
  DANH_SACH_UNG_TUYEN: `${QUAN_LY}danh-sach-ung-tuyen`,
  GOI_DICH_VU_USER: `${QUAN_LY}goi-dich-vu-user`,
  UNG_TUYEN_DI: `${QUAN_LY}ung-tuyen-di`,
  NHAN_SU_BOOK: `${QUAN_LY}nhan-su-book`,
  NHAN_SU_DUOC_BOOK: `${QUAN_LY}nhan-su-duoc-book`,
}
export default ROUTER
