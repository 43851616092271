import React from 'react';
import FlInput from "src/components/FloatingLabel/Input"
import { DEFAULT_PAGE_SIZE } from "src/constants/pageSizeOptions"
import LayoutCommon from 'src/components/Common/Layout';
import { Space, Table, DatePicker, Row, Col, Form, Select } from 'antd';
const ListTable = ({ columns, data, pagination, handleChangeCompanyUser, setPagination, paginations }) => {
    console.log(pagination)
    return (
        <LayoutCommon>
            <Row>
                <Col span={24}>
                    <Form.Item name="TextSearch">
                        <FlInput
                            search
                            allowClear
                            label="Nhập từ khóa tìm kiếm..."
                            onSearch={val => {
                                setPagination({
                                    ...paginations,
                                    CurrentPage: 1,
                                    TextSearch: val,
                                    pageSize: DEFAULT_PAGE_SIZE
                                })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Table
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    handleChangeCompanyUser(record)

                                },
                            }
                        }}
                        pagination={{
                            pagination
                        }}

                        columns={columns} dataSource={data} />
                </Col>
            </Row>
        </LayoutCommon>
    );
}

export default ListTable;
