import styled from "styled-components"

export const StyledFileUser = styled.div`
.job-overview_title_skill {
    /* background: #ACD3FF; */
    border: 1px solid #ACD3FF;
    padding: 0 1em;
    margin-right: 5px;
    border-radius: 3px;
    font-size: .8rem;
    color: #034EA2;
    font-weight: 500;
    }
    td.ant-table-cell:hover {
        cursor: pointer;
    }
`