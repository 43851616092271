//Banner && Footer
export const apiGetAll = `BiddingPakage/GetAll`
export const apiGetAllSkill = `BiddingPakage/GetAllSkill`
export const apiGetAllByAccount = `BiddingPakage/GetAllByAccount`
export const apiGetInsertBiddingPackageAll = `BiddingPakage/GetInsertBiddingPackageAll`
export const apiUpdateBiddingPackage = `BiddingPakage/UpdateBiddingPackage`
export const apiDeleteBiddingPackage = `BiddingPakage/DeleteBiddingPackage`
export const apiSaveBiddingPackage = `BiddingPakage/SaveBiddingPackage`
export const apiUnSaveBiddingPackage = `BiddingPakage/UnSaveBiddingPackage`
export const apiGetSaveBiddingPackageByAccount = `BiddingPakage/GetSaveBiddingPackageByAccount`
export const apiInsertBiddingPackage = `BiddingPakage/InsertBiddingPackage`
export const apiGetAllCompanyIn = `BiddingPakage/GetAllCompanyIn`
export const apiGetAllBiddingPackageIn = `BiddingPakage/GetAllBiddingPackageIn`
export const apiGetListOfCandidatesParticipating =
  "BiddingPakage/GetListOfCandidatesParticipating"
export const apiGetAllCandidateIn = `BiddingPakage/GetAllCandidateIn`
export const apiJoinBiddingPackage = `BiddingPakage/JoinBiddingPackage`
export const apiDetailCalendar = `BiddingPakage/`
export const apiGetBookedCandidate = `BiddingPakage/GetBookedCandidate`
